// Imports
@import "_fonts.scss";
@import "_mixins.scss";
@import "_colors.scss";
@import "_linkcollection.scss";
@import "_loadingindicator.scss";

// Styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: $font_paragraph;
  font-size: $font_paragraph;
}

body {
  margin: 0;
  color: $primary-color;
  font-family: $font_paragraph, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: $fontsize-p;
}

.container {
  padding: 20px;
  text-align: center;
}

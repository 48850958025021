$color-linkcollection: white;

.link-collection {
  &__container {
    width: 100vw;
    height: 100vh;
    padding: 30px 20px;

    /* background: linear-gradient(#336e86, #228b65); */
    background: linear-gradient(
      45deg,
      #405de6,
      #5851db,
      #833ab4,
      #c13584,
      #e1306c,
      #fd1d1d
    );
    color: white;
    text-align: center;
    font-weight: $regular;

    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  &__header {
    padding: 0 20px 30px 20px;
  }

  &__btn {
    padding: 10px;
    margin-top: 15px;
    width: 100%;
    text-align: center;
    color: white;
    text-decoration: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border: 2px solid rgba(218, 218, 218, 0.527);
    border-radius: 2px;
  }
  &__img {
    width: 30px;
    margin: 0 10px;
  }
  &__footer {
    padding: 20px;
    text-align: center;
    background: #228b65;
    color: $color-linkcollection;
    font-size: 1rem;
    font-weight: $light;
  }
}

svg {
    margin:auto;
    background:#fff;
    display:block;
}

.loadingIndicator{
    display: flex;
    vertical-align: middle;
    align-items: center;
    height: 80vh;
}
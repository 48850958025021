@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// VARIABLES
// Fonts

$font_paragraph: "Roboto";
$font_headline: "Poppins";

// Font Sizes

$fontsize-p: 1.2rem;
$fontsize-p-big: 1.3rem;
$fontsize-h1: 1.8rem;
$fontsize-h2: 1.6rem;
$fontsize-h3: 1.2rem;
$fontsize-h4: 1.2rem;

//Font weights

$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;
$black: 900;

// STYLES

h1 {
  font-family: $font_headline;
  font-weight: $bold;
  font-size: $fontsize-h1;
  padding: 10px;
}
h2 {
  font-weight: $regular;
  font-size: $fontsize-h2;
}
h3 {
  font-weight: $regular;
  font-size: $fontsize-h3;
}
h4 {
  font-weight: $regular;
  font-size: $fontsize-h4;
}
